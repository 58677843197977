/* Social Icons */
.social-icons-container {
    position: fixed;
    top: 40%;
    right: 0;
    transform: translateY(-50%);
    z-index: 100;

  }
  
  .social-icon {
    position: fixed;
    right: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: start;
    text-decoration: none;
    color: #fff;
    background-color: #004d9d;
    border-radius: 20px 0 0 20px;
    overflow: hidden;
    transition: width 0.3s ease-in-out;
    border: 1px solid white;
    z-index: 100;
  }
  
  .social-icon:hover {
    width: 140px;
  }
  
  .icon {
    font-size: 20px;
    z-index: 1;
    padding-left: 13px;
  }
  
  .icon-name {
    position: absolute;
    left: 50px;
    font-size: 14px;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    color: #fff;
  }
  
  .social-icon:hover .icon-name {
    opacity: 1;
  }
  
  /* Apply Now Button */
  .apply-now-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 15px 20px;
    background-color: #ff5733;
    color: white;
    font-weight: bold;
    border-radius: 50px;
    text-decoration: none;
    font-size: 16px;
    border: 2px solid #004d9d;
    overflow: hidden;
    animation: wave-animation 2s infinite;
    z-index: 100;

  }
  
  /* WhatsApp Button */
  .whatsapp-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 60px;
    height: 60px;
    background-color: #25d366;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: 2px solid white;
    z-index: 100;
    animation: wave-animation 2s infinite;
  }
  
  .whatsapp-icon {
    font-size: 28px;
    color: white;
  }
  
  /* Wave Animation */
  @keyframes wave-animation {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    }
    50% {
      box-shadow: 0 0 20px 20px rgba(255, 255, 255, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .social-icon {
      margin-top: 40%;
    }
  
    .apply-now-button,
    .whatsapp-button {
      bottom: 10px;
    }
  }
  