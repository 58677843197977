/* Apply box-sizing border-box globally for consistency */
*,
*::before,
*::after {
  box-sizing: border-box;
}

.contact-page {
  font-family: 'Arial', sans-serif;
  background-color: #f9fafb;
  padding: 40px;
}

.contact-content {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 30px;
  margin-bottom: 40px;
  position: relative; /* For the overlay */
  background-image: url('https://res.cloudinary.com/dj9toibx5/image/upload/v1732603273/4_yju61h.webp'); 
  background-size: cover;
  background-position: center;
  padding: 40px;
  border-radius: 12px;
}

/* Overlay for background image */
.contact-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #004c9dd2; 
  border-radius: 12px; 
  z-index: 1; 
}


.contact-details {
  width: 40%;
  text-align: start; 
  position: relative;
  z-index: 2;
}

.contact-details h2 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
  color: white;
}

.contact-details p {
  color: #ffffff;
  font-weight: 400;
  line-height: 1.5;
}
.contact-details b{
    color: #eb951a;
}

/* Contact form styling */
.contact-form {
  width: 50%;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2; 
  text-align: start;
}

.contact-form h2 {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 20px;
}

.contact-form p {
  color: #777;
  margin-bottom: 25px;
}

/* Form inputs and select dropdown styling */
.contact-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #fafafa;
  font-size: 16px;
  text-align: left; /* Align text to the left inside the input */
  box-sizing: border-box; /* Ensures padding and border are included in the width */
}

.contact-input:focus {
  outline: none;
  border-color: #eb951a;
  background-color: #fff;
}

/* Submit button styling */
.contact-button {
  padding: 12px 25px;
  background-color: #eb951a;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-button:hover {
  background-color: #16a085;
}

/* Google Maps iframe styling */
.contact-map {
  width: 100%;
  margin-top: 30px;
}

.contact-map-iframe {
  border: none;
  border-radius: 8px;
}

@media screen and (max-width: 768px) {
  .contact-content {
    flex-direction: column;
    align-items: center;
  }

  .contact-details {
    width: 100%;
    text-align: start;
  }

  .contact-form {
    width: 120%;
  }

  .contact-map {
    margin-top: 20px;
  }
  .contact-page {
    padding: 0px;
  }
}
