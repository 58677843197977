.topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #004d9d; /* Premium blue shade */
    color: #ffffff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* Prevent content overflow */
}

.topbar-left {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-shrink: 1; /* Prevent shrinking too much */
    min-width: 0; /* Allow text to shrink if needed */
}

.topbar-logo {
    width: 55px; /* Adjusted size for smaller screens */
    height: 55px;

    object-fit: cover;
    margin-left: 30px;
    flex-shrink: 0; 
}

.topbar-heading {
    text-align: left;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap; 
    text-transform: uppercase;
    text-overflow: ellipsis;
}

.topbar-title {
    font-size: 1.5rem; /* Adjusted for smaller screens */
    font-weight: 700;
    margin: 0;
    white-space: nowrap; 
}

.topbar-subtitle {
    font-size: 0.7rem; 
    font-weight: 500;
    margin: 3px 0 0 0;
    white-space: wrap; 
    
  
}

.topbar-right {
    text-align: right;
    flex-shrink: 0; /* Prevent shrinking */
    white-space: nowrap; /* Prevent wrapping */
}

.topbar-contact,
.topbar-email {
    font-size: 0.8rem; /* Adjusted font size */
    font-weight: 600;
    margin: 0;
    color: #ffffff;
    margin-right: 30px;
}

.topbar-contact:hover,
.topbar-email:hover {
    color: #cce7f6; /* Slightly lighter on hover */
}

/* Ensure a good layout on small screens */
@media (max-width: 768px) {
    .topbar {
        padding: 8px 15px;
        flex-wrap: nowrap; /* Keep items in a single line */
        display: none;
    }

    .topbar-logo {
        width: 30px;
        height: 30px;
    }

    .topbar-title {
        font-size: 1rem;
    }

    .topbar-subtitle {
        font-size: 0.6rem;
        display: flex;
        flex-wrap: wrap;
    }

    .topbar-contact,
    .topbar-email {
        font-size: 0.7rem;
        display: none;
    }
}
