.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
    margin: 0;
  }
  
  .loading-content {
    text-align: center;
  }
  
  .loading-message {
    margin-top: 20px;
    font-size: 1.5rem;
    color: #333;
  }
  