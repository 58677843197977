.footer {
    background-color: #004d9d; /* Dark Blue Background */
    color: white;
    padding: 40px 20px;
    font-family: Arial, sans-serif;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    text-align: start; /* Ensures text aligns to the start */
  }
  
  .footer-column {
    flex: 1;
    min-width: 200px;
    text-align: start; /* Text alignment for columns */
  }
  
  .footer-column h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    border-bottom: 2px solid white;
    display: inline-block;
    text-align: start;
  }
  
  .footer-column ul {
    list-style: none;
    padding: 0;
    text-align: start;
  }
  
  .footer-column ul li {
    margin: 5px 0;
  }
  
  .footer-column ul li a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-column ul li a:hover {
    color: #ffd700; /* Gold Hover Effect */
  }
  
  .footer-column address {
    font-style: normal;
    line-height: 1.5;
    text-align: start;
  }
  
  .footer-column address a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-column address a:hover {
    color: #ffd700;
  }
  
  .footer-icon {
    margin-right: 8px;
    vertical-align: middle;
  }
  
  .footer-social {
    text-align: start;
    margin-top: 20px;
  }
  
  .footer-social a {
    color: white;
    margin: 0 10px;
    font-size: 1.5rem;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-social a:hover {
    color: #eb951a;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 20px;
    font-size: 0.9rem;
    border-top: 1px solid #fff;
    padding-top: 10px;
  }
  
  .footer-link {
    color: #eb951a; /* Gold color for the links */
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .footer-link:hover {
    color: white; /* White hover effect */
  }
  
  .footer-icon-link {
    margin-right: 10px;
    font-size: 1.2rem;
    color: #eb951a; /* Gold color for the icon */
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-icon-link:hover {
    color: white; /* White hover effect */
  }
  
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: flex-start; /* Align items to start */
    }
  
    .footer-column {
      text-align: start;
    }
  
    .footer-social a {
      margin: 0 5px;
    }
  }
  